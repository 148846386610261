import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
// import Pricing from './components/pricing/pricing';
// import PackagesPage from './components/packages/packagesPage';
// import HeaderSection from './components/HeaderSection';
import SelectedImages from './components/mainContent/selectedImages';
import PreparationTips from './components/preparation/prepTips';
// import Services from './components/mainContent/services';
// import PopularPackages from './components/mainContent/popularPackages';
// import FooterSection from './components/FooterSection';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/selectedImages" element={<SelectedImages />}></Route>
        <Route path="/services" element={<Home jumpToSection="services" />}></Route>
        <Route path="/preparation-tips" element={<PreparationTips />}></Route>
        <Route path="/pricing" element={<Home jumpToSection="packages" />}></Route>
        <Route path="/contacts" element={<Home jumpToSection="contacts" />}></Route>
      </Routes>
    </Router>
    // <>
  );
}

export default App;