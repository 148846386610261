import React from 'react';
import ReactPlayer from 'react-player';
import '../../styles/mainContent/services.css';
import StandardImage1 from '../../images/standardImages/StandardPhotography1.jpg';
import StandardImage2 from '../../images/standardImages/StandardPhotography2.jpg';
import DroneImage1 from '../../images/droneImages/DronePhotography1.jpg';
import DroneImage2 from '../../images/droneImages/DronePhotography2.jpg';
import DroneImage3 from '../../images/droneImages/DronePhotography3.jpg';
import DroneImgGif from '../../images/droneImages/drone-video.gif';
import DroneVideoGif from '../../images/droneImages/DroneVideo2.gif'
import TwoDImage1 from '../../images/2dImages/2dPhotography1.jpg';
import TwoDImage2 from '../../images/2dImages/2dPhotography2.jpg';
import ThreeDImage1 from '../../images/3dImages/3dphotography1.jpg';
import ThreeDImage2 from '../../images/3dImages/3dPhotography2.jpg';
import stageVideo1 from '../../images/stagedVid/staged_video.gif';
import stageVideo2 from '../../images/stagedVid/staged_video_2.gif';
import walkthrough1 from '../../images/socialMediaWalkthroughVid/social_media_walkthrough_video.mp4';
import backgroundImg from '../../images/service-background.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LazyLoad from 'react-lazyload';


const services = [
    { serviceName: 'STANDARD PHOTOGRAPHY (INTERIOR & EXTERIOR)', src: [StandardImage1], height: "250px", type: "image/gifs" },
    { serviceName: 'DRONE AERIAL PHOTOGRAPHY', src: [DroneImage1], height: "250px", type: "image/gifs" },
    { serviceName: '2D FLOOR PLAN', src: [TwoDImage2], height: "250px", type: "image/gifs" },
    { serviceName: '3D FLOOR PLAN', src: [ThreeDImage2], height: "250px", type: "image/gifs" },
    { serviceName: 'SOCIAL MEDIA VIDEO WALKTHROUGH (POTRAIT)', src: [walkthrough1], height: "250px", type: "video" },
    { serviceName: 'MLS VIDEO WALKTHROUGH (LANDSCAPE)', src: [stageVideo1], height: "250px", type: "image/gifs" },
    { serviceName: 'DRONE VIDEO', src: [DroneImgGif], height: "250px", type: "image/gifs" },
    { serviceName: 'STAGED VIDEO (LANDSCAPE OR POTRAIT)', src: [stageVideo2], height: "250px", type: "image/gifs" }
]


const CustomArrowPrev = (onClickHandler, hasPrev, label) =>
    hasPrev && (
        <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-arrow-prev">
            &lt;
        </button>
    );
const CustomArrowNext = (onClickHandler, hasNext, label) =>
    hasNext && (
        <button type="button" onClick={onClickHandler} title={label} className="custom-arrow custom-arrow-next">
            &gt;
        </button>
    );

function Services() {
    return (
        <section id="services" className="services">
            <img src={backgroundImg} className="services-background-img" alt="backgroundImg"/>
            <h1 className="h1-title">OUR SERVICES</h1>
            <div className='border-line-div-services'>
                <span className='border-line-services'/>
            </div>
            <div className="service-grid">
                {services.map((service, index) => (
                    <div className="service" id={service.id}>
                        <Carousel key={index} infiniteLoop showThumbs={false} renderArrowPrev={CustomArrowPrev} renderArrowNext={CustomArrowNext} showStatus={true} showIndicators={false} className='carouselImgs'>
                        {service.src.map((fileSrc, imgIndex) => (
                                <LazyLoad
                                    key={imgIndex}
                                    height={250}
                                    offset={100}
                                    once
                                    placeholder={<div style={{ height: service.height, backgroundColor: '#ccc' }} />}
                                >
                                    {service.type === 'image/gifs' ? (
                                        <div className="image-div">
                                            <img
                                                src={fileSrc}
                                                alt={`${imgIndex + 1} of ${service.serviceName}`}
                                                className="service-img"
                                                style={{ height: service.height }}
                                                loading="lazy"
                                            />
                                        </div>
                                    ) : (
                                        <div className="video-div">
                                            <ReactPlayer
                                                className="video"
                                                url={fileSrc}
                                                playing
                                                muted
                                                loop
                                                height={service.height}
                                                width="100%"
                                                controls={false}
                                                loading="lazy"
                                            />
                                        </div>
                                    )}
                                </LazyLoad>
                            ))}
                        </Carousel>
                        <div key={index} className='details-cont'>
                            <h2 key={index} className='service-heading'>{service.serviceName}</h2>
                            <button key={index} className="lrn-more-btn">Learn More</button>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default React.memo(Services);